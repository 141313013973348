
import logo from '../../assets/learningBlue.svg'
import './notfound.css'

export default function NotFound(){
    return(
        <>
          <nav className="bg-light shadow-lg d-flex justify-content-between nav-bar ">
                <img alt="EYouth-logo" className="mx-1 image--logo" src={logo} />
            </nav>


            <div className="d-flex flex-column justify-content-center congr align-items-center align-content-center">
                <h1 className="text-primary text-notFound">404</h1>
                <h1 className="text-primary">This Page Not Found</h1>
            </div>

           

        </>
    )

}