import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import MarketingLandingPages from "./pages/MarketingTamplet-1/MarketingTamplet";
import NotFound from "./pages/notFound/notfound";
import Thankyou from "./pages/thank-you/thankyou";
import TagManager from 'react-gtm-module'

function App() {
    useEffect(() => {
        TagManager.initialize({ gtmId: 'GTM-NGWXBKB' });
    }, []);
    return (
        <Routes >
            <Route path="/:id" element={<MarketingLandingPages />} />
            <Route path="thankyou" element={<Thankyou />} />
            <Route path={'*'} exact element={<NotFound />} />

        </Routes >
    );
}

export default App;
