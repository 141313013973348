import { axiosInstace } from "./axiosConfig";
import axios from "axios";
// configure axios
axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFToken";

const contenKeys = {
    // 1: "advanced-hr-course-form-registration",
    1: "advanced-hr-course-form-registration",
    2: "business-developmemt-course-form-registration",
    3: "content-writing-registration-form",
    4:"copywriting-course-form-registration",
    5:"digital-marketing-landing-page-form",
    6:"entrepreneurship-landing-page-form",
    7:"human-resources-bundle-landing-page-form",
    8:"instructor-business-finance-form",
    9:"marketing-for-beginners-landing-page-form",
    10:"marketing-management-registration-form",
    11:"office-landing-page-form",
    12:"personal-development-landing-page",
    13:"sales-techniques-course-form-registration"
};


function formRegistration(slug, body) {
    return axiosInstace.post(`eyouth-applicants/submit-application/${slug}?noRedirect=true`, body)
}

function fetchCourcesPage(type, slug) {
    return axios.get(`https://cms.eyouthlearning.com/api/v1/${type}/view/?slug=${slug}`);
}

export { formRegistration, fetchCourcesPage, contenKeys };
