import React, { useEffect, useState } from "react";
// import {  useNavigate } from "react-router-dom";
import "./MarketingTamplet.css";
import star from "../../assets/2.svg";
import star2 from "../../assets/14.svg";
import logoimage from "../../assets/logo.svg"
import logo from "../../assets/learningBlue.svg";
import { TextField, Button } from "@mui/material";
import { HashLink as Link } from "react-router-hash-link";
import { useFormik } from "formik";
import * as Yup from "yup";
import { formRegistration, fetchCourcesPage } from "../../services/FormsService";
import { useParams } from "react-router-dom";
import moment from "moment/moment";
import 'moment/locale/ar'
moment.locale('ar')






export default function MarketingLandingPages2() {
    // const navigate = useNavigate();
    let { id } = useParams();
    const [courseList, setCourseList] = useState({ loading: true });
    const [scrollingPrice, setScrollingPrice] = useState(false)
    const fetchCourse = (itemMapped, callBack) => {
        fetchCourcesPage(itemMapped, id)
            .then((res) => {
                setCourseList((prev) => ({ ...prev, loading: itemMapped !== "testemonial", [itemMapped]: { ...prev?.[itemMapped], data: res.data } }))
                callBack && callBack();
            })
            .catch((err) => setCourseList((prev) => ({ ...prev, loading: itemMapped !== "testemonial", [itemMapped]: { ...prev?.[itemMapped], data: [], err } })))

    }

    useEffect(() => {
        fetchCourse("course", () => fetchCourse("trainer", () => fetchCourse("testemonial")))
        //["course",  "trainer", "testemonial"].map((itemMapped) =>
        //);
    }, []);

    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    const formikValidation = useFormik({
        enableReinitialize: true,
        initialValues: { name: "", email: "", phone: "" },
        validationSchema: Yup.object({
            name: Yup.string().required("Field is Required"),
            email: Yup.string().required("Field is Required").email('Invalid email'),
            phone: Yup.string().required("Field is Required").matches(phoneRegExp, 'Invalid Phone number')
        }),
        onSubmit: (values) => {
            console.log("courseList", courseList)
            formRegistration(courseList.course.data[0].slug, values).then(
                (res) => { window.location.href = "/thankyou" },
                (err) => console.log(err, "err from form")
            );
        }
    });


    if (courseList.loading) return;

    return (
        <>
            {/* navbar */}
            <nav className="bg-light d-flex flex-column nav-bar ">
                <div className="d-flex m-auto w-100 ms-5 header">
                    <img loading="lazy" alt="EYouth-logo" className="mx-1 image--logo" src={logo} />
                    <div className="d-flex m-auto w-100 ms-5 header justify-content-between">

                        <div className="m-2" >
                            <Link to="#register" smooth>
                                {" "}
                                <button className="btn btn-light price-text border text-white p-1 h-100 btn--now m-auto">
                                    سجل الان واحصل علي 50% خصم
                                </button>
                            </Link>
                        </div>
                        <div className={`price m-2 `}>
                            {courseList?.course?.data?.map((course, index) => {
                                return (
                                    <div className="price border d-flex text-center justify-content-between align-items-center bg-website-color">
                                        <img loading="lazy" alt="star" src={star} className="star--price" width="50px" />
                                        <h1 className="text-center price-text m-0">سعر الدورة {course.price} جنية</h1>
                                        <img loading="lazy" alt="star" src={star} className="star--price" width="50px" />
                                    </div>
                                );
                            })}
                        </div>{" "}
                    </div>
                </div>
                {/* price section */}

                {/* end of price */}
            </nav>
            {/* content */}

            <div id="intro" className=" introduction container-fluid">
                <div className="row">
                    <div className="col-md-8 des--course d-flex justify-content-center align-content-center my-5 ">
                        {courseList?.course?.data?.map((course, index) => {
                            return (
                                <div key={index} className="intro--course d-flex flex-column justify-content-center align-items-center">
                                    <h1 className="text-white text-center">{course.title}</h1>
                                    <p className="text-white des--title--p w-100"> {course.description}</p>

                                    {(course.start_date || (course.hours || course.hours !== 0)) &&
                                        <div className="data--stars d-flex justify-content-center">
                                            {(course.hours || course.hours !== 0) &&
                                                <div className="d-flex flex-column justify-content-center align-items-center">
                                                    <div className="position-relative">
                                                        <img loading="lazy" src={star} width="150px" alt="star1" className="mx-5" />
                                                        <h6 className="img--date position-absolute">{(course.hours <= 10) ? course.hours + 'ساعات' : course.hours + 'ساعة'} </h6>
                                                    </div>
                                                    <h6 className="text-white">مده الدورة</h6>
                                                </div>}
                                            {course.start_date && <div className=" d-flex flex-column justify-content-center align-items-center">
                                                <div className="position-relative">
                                                    <img loading="lazy" src={star} alt="star1" width="150px" className="mx-5 " />
                                                    <h6 className="img--date1 position-absolute">{moment(course.start_date).format('LL')}</h6>
                                                </div>
                                                <h6 className="text-white">تاريخ بدايه الدورة </h6>
                                            </div>}
                                        </div>}
                                </div>
                            );
                        })}
                    </div>
                </div>
                {/* <div className="row w-50 m-auto more--btn">
                    <div className="d-flex justify-content-center flex-column align-items-center">
                        <h4 className="text-white">للمزيد</h4>
                        <SouthIcon className="more--icon bounce text-white" />
                    </div>
                </div> */}
            </div>
            <div id="content" className="container my-5">
                <h1 className="text-center">محتوي الدورة</h1>
                {courseList?.course?.data?.map((course, index) => (
                    <ul key={index}>
                        {course?.["content"].map((itemContent, index) => {
                            return (
                                <li key={index} className="h5 ">
                                    <img alt="star-colored" loading="lazy" src={index % 2 === 0 ? star : star2} width="20px" className="ms-2" />
                                    {itemContent}
                                </li>
                            )
                        })}
                    </ul>
                ))}
            </div>
            {/* what will learn */}
            <div className="continer my-5 " id="what-will-learn">
                <h1 className="text-center my-5">ما ستتعلمه في هذه الدورة</h1>
                <div className="row d-flex justify-content-evenly m-auto me-5">
                    <div className="col-md-5 d-flex flex-column justify-content-center">
                        {courseList?.course?.data?.map((course, index) => (
                            <ul key={index}>
                                {course?.["course_benefits"].map((itemContent, index) => {
                                    return (
                                        <li className="li--who">
                                            {itemContent}
                                        </li>
                                    )
                                })}

                            </ul>
                        ))}
                    </div>
                    <div className="col-md-6 image--background position-relative">
                        <img loading="lazy" width="75%" height="" alt="star" src={logoimage} />
                    </div>
                </div>
            </div>
            {/* for who  */}
            <div className="continer my-5 " id="forWho">
                <h1 className="text-center">لمن هذه الدورة</h1>
                <div className="row d-flex justify-content-around m-auto ">
                    <div className="col-md-6 image--background position-relative">
                        <img loading="lazy" width="75%" alt="star" src={logoimage} />
                    </div>
                    <div className="col-md-5 d-flex flex-column justify-content-center ms-5">
                        {courseList?.course?.data?.map((course, index) => (
                            <ul key={index}>
                                {course?.["user_criteria"].map((itemContent, index) => {
                                    return (
                                        <li className="li--who">
                                            {itemContent}
                                        </li>
                                    )
                                })}
                            </ul>
                        ))}
                    </div>
                </div>
            </div>
            {/* about trainers */}
            <div className=" my-5" id="aboutTrainers">
                <h1 className="text-center my-5">عن المدربين</h1>
                <div className="container">
                    <div className="row justify-content-center">
                        {courseList?.trainer?.data?.[0]?.trainers_list?.map((itemMapped, index) => {
                            return (
                                <div className="col-md-4 ms-1  about--trainer border" key={index}>
                                    <div className="d-flex justify-content-center flex-column align-items-center">
                                        <img loading="lazy" alt={itemMapped?.name} width="150px " className="rounded-circle border my-3" src={itemMapped?.image} />
                                        <h5 className="my-3">{itemMapped?.name}</h5>
                                    </div>
                                    <ul>
                                        {itemMapped.description.map((itemContent, index) => {
                                            return (
                                                <li >
                                                    <img src={star} alt="star1" height="" width="15px" className="ms-2" /> {itemContent}
                                                </li>
                                            )
                                        })}

                                    </ul>

                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
            {/* testemonials */}
            <div className="container my-5" id="testemonials">
                <h1 className="text-center">الاراء</h1>
                <div className="row d-flex justify-content-around">
                    {courseList?.testemonial?.data?.[0]?.testemonials_list?.map((itemMapped, index) => {
                        return (
                            <div key={index} className="col-md-5 border d-flex justify-content-around test--div position-relative">
                                <div className="person--testemonials d-flex align-items-center flex-column">
                                    <img alt={itemMapped?.name} src={itemMapped?.image} width="50px" height="40px" />
                                    <h6>{itemMapped?.name}</h6>
                                </div>
                                <div className="my-5">
                                    <h6>{itemMapped.description}</h6>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
            {/*introduction*/}
            <div id="register" className="row justify-content-center align-content-center container-fluid">
                <div className="m-auto col-md-6 d-flex justify-content-center align-content-center my-5 form--register ">
                    <form id="submitForm" onSubmit={formikValidation.handleSubmit} className="bg-light p-5 border rounded w-100 mx-5 position-relative">
                        <img alt="star2" src={star2} className="position-absolute star--intro" width="75px" />

                        <div className="form-row">
                            <div className="col my-3">
                                <label className="mx-3 my-2">الاسم</label>
                                <br />
                                <TextField name="name" className="form--input w-100" onChange={formikValidation.handleChange} value={formikValidation.values.name} />
                                {formikValidation.touched.name && formikValidation.errors.name && <div className="error">{formikValidation.touched.name && formikValidation.errors.name}</div>}
                            </div>
                            <div className="col my-3">
                                <label className="mx-3 my-2">البريد الالكتروني</label>
                                <TextField name="email" className="form-control form--input w-100" onChange={formikValidation.handleChange} value={formikValidation.values.email} />
                                {formikValidation.touched.email && formikValidation.errors.email && <div className="error">{formikValidation.touched.email && formikValidation.errors.email}</div>}
                            </div>
                            <div className="col my-3 my-2">
                                <label className="mx-3 my-3">رقم التليفون </label>
                                <TextField name="phone" className="form-control form--input" onChange={formikValidation.handleChange} value={formikValidation.values.phone} />
                                {formikValidation.touched.phone && formikValidation.errors.phone && <div className="error">{formikValidation.touched.name && formikValidation.errors.phone}</div>}
                            </div>
                            <div className=" d-flex justify-content-center">
                                <Button className="btn btn-primary mb-2 btn--enroll" form="submitForm" type="submit" color="primary" variant="contained">
                                    تسجيل
                                </Button>
                            </div>
                        </div>
                        <img alt="star1" loading="lazy" src={star} className="position-absolute star2--intro" width="75px" />
                    </form>
                </div>
                {/* <div className="row w-50 m-auto more--btn">
                    <div className="d-flex justify-content-center flex-column align-items-center">
                        <h4 className="text-white">للمزيد</h4>
                        <SouthIcon className="more--icon bounce text-white" />
                    </div>
                </div> */}
            </div>
            {/* end of testemonials */}
            {/* price section */}
            {/* <div className="price">
                {courseList?.course?.data?.map((course, index) => {
                    return (
                        <div key={index} className="container">
                            <div className="price border d-flex text-center justify-content-between align-items-center bg-website-color">
                                <img loading="lazy" alt="star" src={star} className="star--price" width="150px" />
                                <h1 className="text-center">سعر الدورة {course.price} جنية</h1>
                                <img loading="lazy" alt="star" src={star} className="star--price" width="150px" />
                            </div>
                        </div>
                    );
                })}
            </div>{" "} */}
            {/* end of price */}
        </>
    );
}
