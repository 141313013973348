import "./thankyou.css"
import logo from "../../assets/learningBlue.svg";

export default function Thankyou() {
 
    return (
        <>
            <nav className="bg-light shadow-lg d-flex justify-content-between nav-bar ">
                <img alt="EYouth-logo" className="mx-1 image--logo" src={logo} />
            </nav>

            <div className="d-flex justify-content-center congr align-items-center align-content-center">
                <h1 className="text-primary">Congratulations, You Were Registered Successfully</h1>
            </div>

        </>
    )


}